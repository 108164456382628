export const pluralise = (count, noun, suffix = 's') =>
  `${noun}${count !== 1 ? suffix : ''}`;

export const searchSuggestions = [
  'beer',
  'victoria park',
  'coffee',
  'wine',
  'gelato',
  'pizza',
  'deli',
  'burger',
  'maylands',
  'northbridge',
  'inglewood',
  'fremantle',
  'leederville'
]
