import React from "react"
import tw from 'twin.macro'
import { Link } from "gatsby"

import CardHero from "./card_hero"
import ContributorPhoto from "./contributor"
import { pluralise } from "../../utils/helpers"
import '@gouch/to-title-case'

const CollectionCard = ({ title, path, places, sourceDirectory, authors, featured }) => (
  <Link
    to={path}
    className="group"
    css={[
      tw`-webkit-backface-visibility[hidden] overflow-hidden backface-visibility[hidden] transform-gpu block text-center text-white no-underline`,
      (featured) ? tw`col-span-1 row-span-1 md:(row-span-2 col-span-2)` : tw`row-span-1 col-span-1`
    ]}>
    <div tw="absolute font-sans top-6 block left-1/2 transform-gpu -translate-x-1/2 flex items-center text-xs z-20 text-shadow[0 1px 3px rgba(0,0,0,0.3)] md:(text-sm)">
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" tw="mr-1"><g fill="#FFF" fillRule="nonzero"><path d="M10.5 4A1.5 1.5 0 0112 5.5v7a1.5 1.5 0 01-1.5 1.5h-9A1.5 1.5 0 010 12.5v-7A1.5 1.5 0 011.5 4h9zm0 1h-9a.5.5 0 00-.5.5v7a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-7a.5.5 0 00-.5-.5zM2.5 3h7a.5.5 0 000-1h-7a.5.5 0 000 1zM4.5 1h3a.5.5 0 000-1h-3a.5.5 0 000 1z"/></g></svg>
      {places.length} {pluralise(places.length, 'Place')}
    </div>
    <div tw="background[#3C3E41] opacity-40 transition-opacity duration-500 absolute inset-0 z-10 group-hover:opacity-20" />
    <div tw="absolute inset-0 transform scale-100 transition[transform 6s ease] group-hover:scale-125">
      <CardHero path={sourceDirectory} alt={title}/>
    </div>
    <div tw="absolute bottom-0 left-0 right-0 z-20 p-6">
      { (authors) && 
        <div tw="flex items-center relative z-20 justify-center">
          {authors.map(({ name, path }) => {
            return <ContributorPhoto name={name} path={path}/>
          })}
        </div>
      }
      <h3 tw="font-serif text-lg md:(text-2xl) 2xl:(text-3xl) text-shadow[0 1px 3px rgb(0 0 0 / 50%)]">
        {title.toTitleCase()}
      </h3>
    </div>
  </Link>
)

export default CollectionCard
