import React from "react"
import 'twin.macro'
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import CollectionCard from "../components/collection/card"
import Footer from "../components/footer"

const CollectionsPage = ({ data }) => {
  const collections = data.allMdx
  return (
    <Layout styleContext="topPadding">
      <Seo title="Collections"/>
      <div tw="pt-14 md:(pt-0)">
        <div tw="grid grid-cols-2 grid-template-rows[33vh] grid-auto-rows[33vh] gap-1 md:(gap-2) lg:(grid-cols-3)">
          {collections.edges.map(({ node }, index) => (
            <CollectionCard 
              authors={node.frontmatter.authors} 
              title={node.frontmatter.title} 
              key={node.fields.slug} 
              path={node.fields.slug} 
              summary={node.frontmatter.summary}
              places={node.frontmatter.places}
              sourceDirectory={node.fields.sourceDirectory}
              featured={(index % 7 === 0)}
            />
          ))}
        </div>
      </div>
      <Footer/>
    </Layout>
  )
}
  
export const query = graphql`
  {
    allMdx(filter: {frontmatter: {draft: {eq: false}}, fields: {sourceName: {eq: "collections"}}}, sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
          fields {
            slug
            sourceDirectory
            sourceName
          }
          frontmatter {
            title
            summary
            places
            date
            authors {
              name
              path
            }
          }
        }
      }
    }
  }
`

export default CollectionsPage
