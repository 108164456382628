import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const CollectionHero = (props) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: {name: {eq: "hero"}}) {
          edges {
            node {
              relativeDirectory
              name
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}

    render={(data) => {
      const image = data.images.edges.find(n => {
        return n.node.relativeDirectory.includes(props.path);
      });
      if (!image) { return null; }
      const imageFluid = image.node.childImageSharp.fluid;
      return (
        <Img
          alt={props.alt}
          fluid={imageFluid}
          style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
          imgStyle={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
        />
      );
    }}
  />
)

export default CollectionHero
