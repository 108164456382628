import React from "react"
import 'twin.macro'

import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'

const ContributorPhoto = ({ name, path }) => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: {relativeDirectory: {eq: "contributors"}}) {
        edges {
          node {
            name
            relativeDirectory
            childImageSharp {
              fluid(maxWidth: 160, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const image = data.allFile.edges.find(n => {
    return (n.node.name === path)
  })

  const imageFluid = image.node.childImageSharp.fluid

  return (
    <div tw="text-white mb-2 md:(mb-3) -mx-2 lg:(mb-4)">
      <Img
        fluid={imageFluid}
        alt={name}
        loading='eager'
        tw="block mx-auto rounded-full mb-2 w-10 h-10 md:(w-20 h-20)"
      />
    </div>
  )
}

export default ContributorPhoto
